/*
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-04-19 17:26:26
 * @FilePath: \ra-web-admin\src\components\SettingDrawer\themeColor.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import client from 'webpack-theme-color-replacer/client'
import generate from '@ant-design/colors/lib/generate'

export default {
  getAntdSerials(color) {
    // 淡化（即less的tint）
    const lightens = new Array(9).fill().map((t, i) => {
      return client.varyColor.lighten(color, i / 10)
    })
    // colorPalette变换得到颜色值
    const colorPalettes = generate(color)
    const rgb = client.varyColor.toNum3(color.replace('#', '')).join(',')
    return lightens.concat(colorPalettes).concat(rgb)
  },
  changeColor(newColor) {
    let isDevEnv = false
    const theOrigin = window.location.origin
    if (theOrigin.indexOf('.testiot.revolution.auto') > -1 || theOrigin.indexOf('.iot.revolution.auto') > -1) {
      isDevEnv = false
    } else {
      isDevEnv = true
    }
    if (!isDevEnv) {
      return new Promise((resolve) => {
        resolve()
      })
    }
    var options = {
      newColors: this.getAntdSerials(newColor), // new colors array, one-to-one corresponde with `matchColors`
      changeUrl(cssUrl) {
        return `/${cssUrl}` // while router is not `hash` mode, it needs absolute path
      }
    }
    return client.changer.changeColor(options, Promise)
  }
}
