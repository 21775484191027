import { render, staticRenderFns } from "./SettingItem.vue?vue&type=template&id=67a78d35&scoped=true&"
import script from "./SettingItem.vue?vue&type=script&lang=js&"
export * from "./SettingItem.vue?vue&type=script&lang=js&"
import style0 from "./SettingItem.vue?vue&type=style&index=0&id=67a78d35&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67a78d35",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67a78d35')) {
      api.createRecord('67a78d35', component.options)
    } else {
      api.reload('67a78d35', component.options)
    }
    module.hot.accept("./SettingItem.vue?vue&type=template&id=67a78d35&scoped=true&", function () {
      api.rerender('67a78d35', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SettingDrawer/SettingItem.vue"
export default component.exports